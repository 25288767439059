.react-datepicker {
  border: 0px !important;
}

.react-datepicker__header {
  background-color: transparent;
  border-bottom: none;
}

.react-datepicker__day-name {
  display: inline-block;
  width: 2rem;
  line-height: 2rem;
  text-align: center;
  margin: 0.25rem;
}

.react-datepicker__day-names {
  margin-top: 8px;
  margin-bottom: -12px;
}

.react-datepicker__day-name {
  font-family: Inter, sans-serif;
  font-size: var(--chakra-fontSizes-xs);
  font-weight: var(--chakra-fontWeights-semibold);
  color: var(--chakra-colors-neutral-dark);
}

.react-datepicker__day {
  border-radius: var(--chakra-radii-full);
  display: inline-block;
  width: 2rem;
  line-height: 2rem;
  text-align: center;
  margin: 0.25rem;
  background-color: transparent;
  color: var(--chakra-colors-neutral-darkest);
}

.react-datepicker__day:hover {
  border-radius: var(--chakra-radii-full);
  background-color: var(--chakra-colors-brand-dark);
  color: white;
}

.react-datepicker__day--selected,
.react-datepicker__day--in-range {
  background-color: var(--chakra-colors-brand-dark);
  color: var(--chakra-colors-neutral-lightest);
}

.react-datepicker__day--disabled, .react-datepicker__day--disabled:hover {
  background-color: transparent;
  color: var(--chakra-colors-neutral-medium);
}


.react-datepicker__day--outside-month > * {
  display: none;
}