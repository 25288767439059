.react-datepicker__triangle {
  display: none;
}

.react-datepicker-popper {
  border: 1px solid #c5ced6;
  border-radius: 4px;
  background-color: #fff;
  padding: 8px;
  transform: translate3d(0px, 64px, 0px) !important;
  z-index: 5;
}

.react-datepicker {
  max-width: min-content;
}
